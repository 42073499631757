/** @flow */


/** import dependencies */
import React from 'react';
import styles from './styles.scss';


class Footer extends React.Component<{}> {

    render() {
        return (
            <div className={styles.wrapper}>
                &copy; 2018 Sherlock &amp; S&apos;mores .
            </div>
        );
    }

}


export default Footer;
