/** @flow */


/** import dependencies */
import React from 'react';
import Header from 'Components/Header';
import styles from './styles.scss';


class ThankYou extends React.Component<{}> {

    render() {
        return (
            <div className={styles.wrapper}>

                <div className={styles.quote}>
                    <div className={styles.line} data-reveal />
                    <Header text="Thank You" style={{ textAlign: 'center' }} />
                    <span className={styles.author} data-reveal>We look forward to celebrating this special moment with you: our closest family and friends.</span>
                </div>

            </div>
        );
    }

}


export default ThankYou;
