/** @flow */


/** import dependencies */
import React from 'react';
import styles from './styles.scss';
import type { Props } from './types';


class Header extends React.Component<Props> {

    render() {
        const { text, ...otherProps } = this.props;

        return (
            <p className={styles.wrapper} {...otherProps}>
                {text}
                <span className={styles.offset}>
                    {text}
                </span>
            </p>
        );
    }

}


export default Header;
