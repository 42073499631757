/** @flow */


/** import dependencies */
import React from 'react';
import floralFrame from 'Assets/images/floral-frame-full.png';
import styles from './styles.scss';


class Splash extends React.Component<{}> {

    render() {
        return (
            <div className={styles.wrapper}>

                <div className={styles.floralFrame}>
                    <img src={floralFrame} width="100%" alt="" />
                </div>

                <div className={styles.scrollDown}>
                    <div className={styles.mouseY}>
                        <div className={styles.scroller} />
                    </div>
                </div>

            </div>
        );
    }

}


export default Splash;
