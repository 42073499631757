/** @flow */


/** import dependencies */
import React from 'react';
import Header from 'Components/Header';
import image1 from 'Assets/images/9303.jpg';
import image2 from 'Assets/images/0211.jpg';
import styles from './styles.scss';


class OurStory extends React.Component<{}> {

    render() {
        return (
            <div className={styles.wrapper}>

                <div className={styles.content}>
                    <div className={styles.firstImageWrapper} data-floral-1>
                        <div className={styles.firstImage} data-bordered>
                            <img src={image2} alt="" data-parallax-image />
                        </div>
                    </div>
                    <div className={styles.sarah} data-reveal>
                        <Header text="Sarah" />
                        <p className={styles.story}>
                            Sarah enjoys long walks through pumpkin patches and curling up with a good book (&#34;good&#34;,
                            meaning Harry Potter in most cases). She was Croatian before it was even cool (thanks, Game
                            of Thrones) and her friends affectionately call her a Sour Patch Kid &mdash; first
                            she&#39;s sour then she&#39;s sweet. Super sweet. Like the kind of sweet you fall head over heels
                            for (just ask Lawren).
                        </p>
                    </div>
                    <div className={styles.secondImage}>
                        <img src={image1} alt="" data-parallax-image />
                    </div>
                    <div className={styles.lawren} data-reveal>
                        <Header text="Lawren" />
                        <p className={styles.story}>
                            You can find this coffee addicted, sarcastic hunk of meat trolling anyone and everyone around
                            him or watching Bachelor with the girls every Monday night (Team Peter #neverforget). When
                            he&#39;s not being an adult (which is often), he can be found sword fighting with his 6-year-old brother.
                        </p>
                    </div>
                </div>

            </div>
        );
    }

}


export default OurStory;
