/** @flow */


/** import dependencies */
import React from 'react';
import Header from 'Components/Header';
import venueMarker from 'Assets/images/venue-marker.png';
import airportMarker from 'Assets/images/airport-marker.png';
import styles from './styles.scss';


class Details extends React.Component<{}> {


    ref = React.createRef();


    componentDidMount() {
        const venue = { lat: 35.200406, lng: -80.8712304 };
        const airport = { lat: 35.2144026, lng: -80.9495033 };
        const map = new google.maps.Map(this.ref.current, {
            center: venue,
            zoom: 11,
            disableDefaultUI: true,
            scrollwheel: false,
            styles: [
                {
                    elementType: 'geometry',
                    stylers: [{ color: '#f5f5f5' }],
                },
                {
                    elementType: 'labels.icon',
                    stylers: [{ visibility: 'off' }],
                },
                {
                    elementType: 'labels.text.fill',
                    stylers: [{ color: '#616161' }],
                },
                {
                    elementType: 'labels.text.stroke',
                    stylers: [{ color: '#f5f5f5' }],
                },
                {
                    featureType: 'administrative.land_parcel',
                    elementType: 'labels.text.fill',
                    stylers: [{ color: '#bdbdbd' }],
                },
                {
                    featureType: 'poi',
                    elementType: 'geometry',
                    stylers: [{ color: '#eeeeee' }],
                },
                {
                    featureType: 'poi',
                    elementType: 'labels.text.fill',
                    stylers: [{ color: '#757575' }],
                },
                {
                    featureType: 'poi.park',
                    elementType: 'geometry',
                    stylers: [{ color: '#e5e5e5' }],
                },
                {
                    featureType: 'poi.park',
                    elementType: 'labels.text.fill',
                    stylers: [{ color: '#9e9e9e' }],
                },
                {
                    featureType: 'road',
                    elementType: 'geometry',
                    stylers: [{ color: '#ffffff' }],
                },
                {
                    featureType: 'road.arterial',
                    elementType: 'labels.text.fill',
                    stylers: [{ color: '#757575' }],
                },
                {
                    featureType: 'road.highway',
                    elementType: 'geometry',
                    stylers: [{ color: '#dadada' }],
                },
                {
                    featureType: 'road.highway',
                    elementType: 'labels.text.fill',
                    stylers: [{ color: '#616161' }],
                },
                {
                    featureType: 'road.local',
                    elementType: 'labels.text.fill',
                    stylers: [{ color: '#9e9e9e' }],
                },
                {
                    featureType: 'transit.line',
                    elementType: 'geometry',
                    stylers: [{ color: '#e5e5e5' }],
                },
                {
                    featureType: 'transit.station',
                    elementType: 'geometry',
                    stylers: [{ color: '#eeeeee' }],
                },
                {
                    featureType: 'water',
                    elementType: 'geometry',
                    stylers: [{ color: '#c9c9c9' }],
                },
                {
                    featureType: 'water',
                    elementType: 'labels.text.fill',
                    stylers: [{ color: '#9e9e9e' }],
                },
            ],
        });
        new google.maps.Marker({ position: venue, map, icon: venueMarker });
        new google.maps.Marker({ position: airport, map, icon: airportMarker });
    }


    render() {
        return (
            <div className={styles.wrapper}>

                <div className={styles.content} data-floral-2>
                    <div className={styles.mapFrame}>
                        <div className={styles.map} ref={this.ref} />
                    </div>
                    <div className={styles.location} data-reveal>
                        <Header text="Location" style={{ textAlign: 'center' }} />
                        <div className={styles.description}>
                            <p>
                                The ceremony and reception will be held in Norfolk Hall at Suffolk Punch, a building in the
                                heart of Charlotte&apos;s historic South End.
                            </p>
                            <p>
                                Norfolk Hall at Suffolk Punch
                                <br />
                                2905 Griffith Street
                                <br />
                                Charlotte, NC 28203
                            </p>
                            <a href="https://goo.gl/maps/HHAh4Lmz5zv" target="_blank" rel="noopener noreferrer">
                                Get Directions
                            </a>
                        </div>
                    </div>
                    <div className={styles.accommodations} data-reveal>
                        <Header text="Accomodations" style={{ textAlign: 'center' }} />
                        <div className={styles.description}>
                            <p>
                                A block has been reserved for guests at the the Marriott Courtyard in Charlotte City Center.
                                Just tell them you&apos;re with the Anderson & Whitehead wedding.
                            </p>
                            <p>
                                Marriott Courtyard
                                <br />
                                237 South Tryon Street
                                <br />
                                Charlotte, NC 28202
                            </p>
                            <a href="https://www.marriott.com/events/start.mi?id=1554396530736&key=GRP" target="_blank" rel="noopener noreferrer">
                                Book a Room
                            </a>
                        </div>
                    </div>
                    <div className={styles.transportation} data-reveal>
                        <div className={styles.card}>
                            <Header text="Travel" style={{ textAlign: 'center' }} />
                            <div className={styles.description}>
                                <p>
                                    For those flying in, Charlotte Douglas International Airport is a short, 10-15 minute Uber
                                    or taxi to the Marriott Courtyard.
                                </p>
                                <p>
                                    A complimentary shuttle will run to and from the hotel to the wedding venue.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        );
    }

}


export default Details;
