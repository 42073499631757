/** @flow */


/** import dependencies */
import React from 'react';
import Splash from 'Scenes/Splash';
import Quote from 'Scenes/Quote';
import OurStory from 'Scenes/OurStory';
import Details from 'Scenes/Details';
import Timeline from 'Scenes/Timeline';
import Gallery from 'Scenes/Gallery';
import Registry from 'Scenes/Registry';
import ThankYou from 'Scenes/ThankYou';
import Footer from 'Scenes/Footer';
import Scrollbar from 'smooth-scrollbar';
import 'aos/dist/aos.css';
import styles from './styles.scss';


class Main extends React.Component<{}> {

    ref = React.createRef();


    componentDidMount() {
        const scrollBar = Scrollbar.init(this.ref.current);
        scrollBar.addListener(this.reveal);
        scrollBar.addListener(this.handleParallax);
    }


    reveal = () => {
        const revealElements = document.querySelectorAll('[data-reveal]');
        revealElements.forEach((el) => {
            const rect = el.getBoundingClientRect();
            const elemTop = rect.top;
            const elemBottom = rect.bottom;

            // Only completely visible elements return true:
            // const isVisible = (elemTop >= 0) && (elemBottom <= window.innerHeight);
            // Partially visible elements return true:
            const isVisible = elemTop < (window.innerHeight - 100) && elemBottom >= 0;
            if (isVisible) el.classList.add('revealed');
        });
    };


    handleParallax = () => {
        const parallaxElements = document.querySelectorAll('[data-parallax-image]');
        parallaxElements.forEach((element) => {
            const el = element;
            const rect = el.getBoundingClientRect();
            el.classList.add('parallax-image');
            const elemCenter = rect.top + (rect.height / 2);
            const offset = ((window.innerHeight / 2) - elemCenter) / 75;
            el.style.transform = (`translate3d(-50%, -${50 - offset}%, 0)`);
        });
    };


    render() {
        return (
            <div className={styles.wrapper} ref={this.ref}>
                <Splash />
                <Quote />
                <OurStory />
                <Details />
                <Timeline />
                <Gallery />
                <Registry />
                <ThankYou />
                <Footer />
            </div>
        );
    }

}


export default Main;
