/** @flow */


/** import dependencies */
import React from 'react';
import Header from 'Components/Header';
import styles from './styles.scss';


class Registry extends React.Component<{}> {

    render() {
        return (
            <div className={styles.wrapper}>

                <Header text="Registry Coming Soon" style={{ textAlign: 'center' }} />

            </div>
        );
    }

}


export default Registry;
