/** @flow */


/** import react stuffs */
import React from 'react';
import ReactDOM from 'react-dom';
import { AppContainer } from 'react-hot-loader';

/** import component base styles */
import 'Base/styles/index.scss';
import Main from 'Scenes/Main';


/** render our app with our HMR wrapper */
const render = () => {

    const rootElement = document.getElementById('root');
    if (rootElement) {
        ReactDOM.render(
            <AppContainer>
                <Main />
            </AppContainer>,
            rootElement,
        );
    }

};
render();

/** configure our HMR */
if (module.hot) {
    module.hot.accept('./scenes/Main', () => render());
}
