/** @flow */


/** import dependencies */
import React from 'react';
import arrive from 'Assets/images/spaceship.png';
import ceremony from 'Assets/images/ceremony.png';
import cocktail from 'Assets/images/cocktail.png';
import plate from 'Assets/images/plate.png';
import thanks from 'Assets/images/thanks.png';
import styles from './styles.scss';


class Timeline extends React.Component<{}> {

    render() {
        return (
            <div className={styles.wrapper}>
                <div data-floral-4>
                    <div className={styles.timeline} data-floral-3>
                        <div className={styles.item}>
                            <img src={arrive} alt="" className={styles.arriveIcon} />
                            <div className={styles.description}>
                                <div className={styles.time}>4:30PM</div>
                                <div>Guests Arrive</div>
                            </div>
                        </div>
                        <div className={styles.item}>
                            <img src={ceremony} alt="" className={styles.ceremonyIcon} />
                            <div className={styles.description}>
                                <div className={styles.time}>5:00PM</div>
                                <div>Ceremony</div>
                            </div>
                        </div>
                        <div className={styles.item}>
                            <img src={cocktail} alt="" className={styles.cocktailIcon} />
                            <div className={styles.description}>
                                <div className={styles.time}>5:30PM</div>
                                <div>Cocktails</div>
                            </div>
                        </div>
                        <div className={styles.item}>
                            <img src={plate} alt="" className={styles.plateIcon} />
                            <div className={styles.description}>
                                <div className={styles.time}>7:00PM</div>
                                <div>Dinner</div>
                            </div>
                        </div>
                        <div className={styles.item}>
                            <img src={thanks} alt="" className={styles.thanksIcon} />
                            <div className={styles.description}>
                                <div className={styles.time}>10:30PM</div>
                                <div>Late Night</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}


export default Timeline;
