/** @flow */


/** import dependencies */
import React from 'react';
import styles from './styles.scss';


class Quote extends React.Component<{}> {

    render() {
        return (
            <div className={styles.wrapper}>

                <div className={styles.quote}>
                    <p data-reveal>
                        This morning,
                        <br />
                        with her,
                        <br />
                        having coffee.
                        <span className={styles.quoteOffset}>
                            This morning,
                            <br />
                            with her,
                            <br />
                            having coffee.
                        </span>
                    </p>
                    <span className={styles.author} data-reveal>Johnny Cash when asked for his description of paradise.</span>
                    <div className={styles.line} data-reveal />
                </div>

            </div>
        );
    }

}


export default Quote;
