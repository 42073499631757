/** @flow */


/** import dependencies */
import React from 'react';
import Header from 'Components/Header';
import image2 from 'Assets/images/9470.jpg';
import image3 from 'Assets/images/0841.jpg';
import image4 from 'Assets/images/0166.jpg';
import image5 from 'Assets/images/1063.jpg';
import styles from './styles.scss';


class Gallery extends React.Component<{}> {

    render() {
        return (
            <div className={styles.wrapper}>

                <div className={styles.line} />

                <Header text="Gallery" style={{ textAlign: 'center', marginBottom: '5rem' }} />

                <div className={styles.content}>
                    <div className={styles.firstImageWrapper} data-floral-5>
                        <div className={styles.firstImage} data-bordered>
                            <img src={image4} alt="" data-parallax-image />
                        </div>
                    </div>
                    <div className={styles.secondImage}>
                        <img src={image2} alt="" data-parallax-image />
                    </div>
                    <div className={styles.thirdImageWrapper} data-floral-6>
                        <div className={styles.thirdImage} data-bordered>
                            <img src={image5} alt="" data-parallax-image />
                        </div>
                    </div>
                    <div className={styles.fourthImage}>
                        <img src={image3} alt="" data-parallax-image />
                    </div>
                </div>

            </div>
        );
    }

}


export default Gallery;
